.DateRangePickerInput {
  border: 1px solid #ccccccff;
  border-radius: 4px;
  min-width: 36px;
  position: relative;
}

.SingleDatePicker_picker {
  z-index: 1301;
}

.SingleDatePickerInput {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e5e5e5;
  background-color: transparent;
}

.DateRangePickerInput_calendarIcon {
  min-width: 26px;
  padding: 0;
  margin: 1px 0;
  outline: none;
  position: relative;
}

.CalendarDay {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0);
}

.CalendarMonth_caption {
  font-size: 16px;
  font-family: 'GraphikMedium';
  margin-top: 5px;
  margin-bottom: 5px;
}

.CalendarDay {
  font-family: 'GraphikRegular';
  font-size: 16px;
  color: #757575;
}

.CalendarDay__outside {
  color: #bdbdbd;
}

.CalendarDay__blocked_out_of_range {
  color: #bdbdbd;
}

.CalendarDay__today {
  background: #f5f5f5;
}

.CalendarDay__default:hover,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected {
  background: #06556e;
  border-radius: 4px;
  outline: none;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0);
}

.CalendarDay__selected_span {
  background: #dde5e7;
}

.CalendarDay__hovered_span {
  background: #dde5e7;
  border: 1px solid rgba(0, 0, 0, 0);
}

.CalendarDay__selected:hover {
  background: #2c7389;
  border: 1px solid rgba(0, 0, 0, 0);
}

.CalendarDay__selected_span:hover {
  background: #f3f7f8;
  color: #757575;
}

.DateInput {
  display: none;
}

.DateRangePickerInput_arrow {
  display: none;
}

.DateRangePicker {
  font-family: 'GraphikRegular';
}

.DateRangePicker_picker {
  z-index: 9;
  margin-bottom: 6rem;
}

.DayPicker_transitionContainer {
  border-right: 0.5px solid #cccccc;
}

.DayPickerNavigation_button,
.DayPickerNavigation_button:hover {
  outline: none;
  border: none;
}

.DayPickerNavigation_button:hover svg {
  fill: #2c7389;
}

.DayPicker_calendarInfo__horizontal {
  position: relative;
  z-index: 2;
}

.SingleDatePickerInput_calendarIcon {
  background-color: white;
}

.SingleDatePickerInput_calendarIcon:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.DayPicker_weekHeader_ul {
  font-family: 'GraphikRegular';
  font-size: 16px;
}

#closureCalendar .DateRangePicker_picker {
  margin-bottom: -1rem;
}

/* do not show selector on extra small mobile screens */
@media (max-height: 700px) and (max-width: 400px) {
  .DayPicker_focusRegion,
  .DayPicker_weekHeaders {
    display: none;
  }
}
