$defaultFontStack: Helvetica, Arial, sans-serif;
$fontFamilyRegular: GraphikRegular, $defaultFontStack;
$fontFamilyMedium: GraphikMedium, $defaultFontStack;
$fontFamilyBold: GraphikBold, $defaultFontStack;

html,
body,
#__next {
  font-family: $fontFamilyRegular;
  height: 100%;
  padding: 0;
  margin: 0;
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
  // disables link previews on safari, which look bad on Webviews
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

form {
  height: 100%;
}

/** 
  dndkit uses the LiveRegion to store text for screen readers
  dndkit styles the region as position: fixed by default https://github.com/clauderic/dnd-kit/blob/a553fcca776ac2663296a588bc86420d86554198/packages/accessibility/src/components/LiveRegion/LiveRegion.tsx#L10
  on render, it gets overriden to position: absolute;
  which places the region at the bottom of the page adding extra whitespace
  we ensure the position is fixed to avoid unnecessary whitespace
*/
div[id*='DndLiveRegion'] {
  position: fixed !important;
}

/**
  Overwrite 'react-color' color picker styles for input and label
*/
[id^='rc-editable-input'],
[for^='rc-editable-input'] {
  font-family: GraphikRegular;
  font-size: 16px !important;
  padding: 4px;
}
