#ordering-closures-calendar .DateRangePickerInput {
  border: none;
  min-width: 36px;
  height: 36px;
  position: relative;
}

#ordering-closures-calendar .DateRangePicker,
#ordering-closures-calendar .DateRangePickerInput {
  width: 100%;
}
#ordering-closures-calendar .DateRangePickerInput {
  border: none;
}

#ordering-closures-calendar .DateRangePickerInput_calendarIcon {
  min-width: 26px;
  height: 28px;
  padding: 0;
  margin: 5px 0;
  outline: none;
  position: relative;
}

#ordering-closures-calendar .DateRangePickerInput_calendarIcon:focus::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -2px;
  height: calc(100% + 8px);
  width: calc(100% + 2px);
  z-index: 2;
  border: none;
}

#ordering-closures-calendar .DateRangePickerInput_calendarIcon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

#ordering-closures-calendar .DateRangePickerInput_calendarIcon:focus::after {
  border: none;
}

#ordering-closures-calendar .CalendarDay {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0);
}

#ordering-closures-calendar .CalendarMonth_caption {
  font-size: 16px;
  font-family: 'GraphikMedium';
  margin-top: 5px;
  margin-bottom: 5px;
}

#ordering-closures-calendar .CalendarDay {
  font-family: 'GraphikRegular';
  font-size: 16px;
  color: #757575;
}

#ordering-closures-calendar .CalendarDay__outside {
  color: #bdbdbd;
}

#ordering-closures-calendar .CalendarDay__today {
  background: #f5f5f5;
}

#ordering-closures-calendar .CalendarDay__default:hover,
#ordering-closures-calendar .CalendarDay__hovered_span:hover,
#ordering-closures-calendar .CalendarDay__selected {
  background: #06556e;
  border-radius: 4px;
  outline: none;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0);
}

#ordering-closures-calendar .CalendarDay__selected_span {
  background: #dde5e7;
}

#ordering-closures-calendar .CalendarDay__hovered_span {
  background: #dde5e7;
  border: 1px solid rgba(0, 0, 0, 0);
}

#ordering-closures-calendar .CalendarDay__selected:hover {
  background: #2c7389;
  border: 1px solid rgba(0, 0, 0, 0);
}

#ordering-closures-calendar .CalendarDay__selected_span:hover {
  background: #f3f7f8;
  color: #757575;
}

#ordering-closures-calendar .DateInput {
  display: none;
}

#ordering-closures-calendar .DateRangePickerInput_arrow {
  display: none;
}

#ordering-closures-calendar .DateRangePicker {
  font-family: 'GraphikRegular';
}

#ordering-closures-calendar .DateRangePicker_picker {
  z-index: 9;
  margin-bottom: 6rem;
}

#ordering-closures-calendar .DayPickerNavigation_button,
#ordering-closures-calendar .DayPickerNavigation_button:hover {
  outline: none;
  border: none;
}

#ordering-closures-calendar .DayPickerNavigation_button:hover svg {
  fill: #2c7389;
}

#ordering-closures-calendar .DayPicker_calendarInfo__horizontal {
  position: relative;
  z-index: 2;
}

#ordering-closures-calendar .SingleDatePickerInput_calendarIcon:disabled {
  background-color: white;
}

/* do not show selector on extra small mobile screens */
@media (max-height: 700px) and (max-width: 400px) {
  .DayPicker_focusRegion,
  .DayPicker_weekHeaders {
    display: none;
  }
}
