@font-face {
  font-family: 'GraphikRegular';
  src: url('Graphik-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikMedium';
  src: url('Graphik-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikBold';
  src: url('Graphik-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikRegular';
  src: url('Graphik-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GraphikMedium';
  src: url('Graphik-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GraphikBold';
  src: url('Graphik-BoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}
